import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { SearchState } from "../models/userManagement-search";
import { UserManagementItem } from "../models/userManagementItem";
import { UserManagementSort } from "../models/userManagement-sort";
import { UserManagementTableFilterItem } from "../models/userManagement-table-filter-item";
import * as moment from 'moment';
import { AppSettings } from "src/shared/app-settings";
import { GlobalHelperService } from "../globalHelper/global-helper.service";

@Injectable()

@Injectable({
  providedIn: 'root'
})
export class NewdealService {
  
  constructor(private myhttp: HttpClient,public globalHelperService:GlobalHelperService) { }
  getAppendURL(dropdownName: string) {
    let url: any = "";
    switch (dropdownName) {
      case "MyDealRoles":
        return url = "api/MyDealRoles";
        break;
      case "MyDealPursuitRoles":
        return url = "api/MyDealPursuitRoles";
        break;
      case "MyDealSwiftRoles":
        return url = "api/MyDealSwiftRoles";
        break;
      case "MyDealDeloitteUSEntity":
        return url = "api/MyDealDeloitteUSEntity";
        break;
      case "MyDealSalesOffice":
        return url = "api/MyDealSalesOffice";
        break;  
        case "NewDealDocumentTypes":
          return url = "api/NewDealDocumentTypes";
          break; 
        case "DealWBSDocumentTypes":
            return url = "api/DealWBSDocumentTypes";
            break;
        case "DealWbsSetupForm":
            return url = "api/DealWbsSetupForm";
            break;  
        case "ClosingCheckListForm":
           return url = "api/ClosingCheckListForm";
            break; 
        case "NewDealMarketOfferingMap":
          return url = "api/NewDealMarketOfferingMap";
          break;   
          case "NewDealAttachments":
            return url = "api/NewDealAttachments";
            break; 
          case "DealWbsAttachments":
            return url = "api/DealWbsAttachments";
            break; 
          case "NewDealAdditionalDocumentTypes":
            return url = "api/NewDealAdditionalDocumentTypes";
            break;        
            case "DocumentManager":
            return url = "api/DocumentManager";
            break;  
            case "NewDealSetupForm": 
            return url="api/NewDealSetupForm" ;
            break;   
            case "MyDeal": 
            return url="api/MyDeal" ;
            break;
             case "NewDealAdminReviewRotationAssignment": 
            return url ="api/NewDealAdminReviewRotationAssignment" ;
            break;
            case "MyDealDealTeam": 
            return url="api/MyDealDealTeam" ;
            break;  
            case "DealTeamAuditLog": 
            return url="api/DealTeamAuditLog" ;
            break;   
        case "NewDealReviewComments":
            return url = "api/NewDealReviewComments";
            break;
        case "EngagementHealthObject":
          return url = "api/EngagementHealthObject";
          break;
        case "CrossBorderForm":
          return url = "api/CrossBorderForm";
          break;
        case "DealTeamAuditLog": 
          return url = "api/DealTeamAuditLog";
          break; 
        case "ClosingChecklistAttachments": 
          return url = "api/ClosingChecklistAttachments";
          break;  
      default:
        return "";
        break;
    }
  }
  GetDropDownData(appendedURL: any,formId?:any): Promise<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    let url = "";
    if(formId !=null && formId != undefined)
    {
      url = envURL + '/' + appendedURL + '/GetList?formID=' + formId;
    }
    else
    {
      url = envURL + '/' + appendedURL + '/GetList';
    }    
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    }).toPromise();
  }

  GetFormAttachments(appendedURL: any,formID:any,formType:any):Observable<any>{  
    var lambdaUrl = '';
    switch(formType)
    {
      case AppSettings.FormTypes.NewDealSetupForm:
        lambdaUrl = environment.Newdealform;
        break;
      case AppSettings.FormTypes.DealWbsSetupForm:
        lambdaUrl = environment.DealWbsform;
        break;
      case AppSettings.FormTypes.ClosingCheckListForm:
        lambdaUrl = environment.ClosingChecklistForm;
        break;
    }
    //lambdaUrl = (formType == AppSettings.FormTypes.NewDealSetupForm ? environment.Newdealform :environment.DealWbsform);
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,lambdaUrl);
    const url = envURL + '/' + appendedURL + '/GetList?formId=' + formID;      
       return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
  downloadAttachments(formId: any,fileName: any,attachEnviroment: any,pageSource:any):Observable<any>
  {
    const appendedURL = this.getAppendURL(AppSettings.DocumentManager);
    var data = JSON.stringify({FormID: formId.toString(), FileName: fileName,AttachEnviroment:attachEnviroment,PageSource:pageSource})
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DocumentManager)
    const url = envURL + '/' + appendedURL + '/DownloadFile';   
     return this.myhttp.post(url, data, {
      headers: new HttpHeaders({
         'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  DeleteFile(attachEnvironment:any,formId: any,fileName: any,pageSource:any):Observable<any>
  {
    const appendedURL = this.getAppendURL(AppSettings.DocumentManager);
    let attachmentType = 'nonxbforms';
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DocumentManager)
    const url = envURL+ '/' + appendedURL + '/DeleteFile?formID='+formId+'&filename='+encodeURIComponent(fileName)+'&pageSource='+pageSource+'&attachEnvironment='+attachEnvironment+'&attachmentType='+attachmentType;      
     return this.myhttp.delete(url,{
      headers: new HttpHeaders({        
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    });
  }
  DeleteAttachment(formId: any,fileName:any,formType:any):Observable<any>
  {
    let appendedURL = '',envURL = '';
    switch(formType)
    {
       case AppSettings.FormTypes.NewDealSetupForm:
          appendedURL = this.getAppendURL(AppSettings.newdealAttachmentsList);
          envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform);
          break;
       case AppSettings.FormTypes.DealWbsSetupForm:
          appendedURL = this.getAppendURL(AppSettings.dealWbslAttachmentsList);
          envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform);
          break;
       case AppSettings.FormTypes.ClosingCheckListForm:
          appendedURL = this.getAppendURL(AppSettings.closingCheckListAttachmentsList);
          envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm);
          break;
    }
    
    const url = envURL + '/' + appendedURL + '/Delete';
    var data = JSON.stringify({ FormID: formId.toString(), FileName: fileName })
    return this.myhttp.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    });
  }

  uploadFile(uploadRequestObj:any ):Observable<any>
  {         
    const appendedURL = this.getAppendURL(AppSettings.DocumentManager);    
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DocumentManager)
    const url = envURL + '/' + appendedURL + '/UploadFile';
     return this.myhttp.post(url, uploadRequestObj, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }

  AddAtachment(attachmentReqObj:any,formType:any):Observable<any>
  { 
    var appendedURL = '';
    var envURL = '';

    if(formType==AppSettings.FormTypes.NewDealSetupForm)
    {
      appendedURL = this.getAppendURL(AppSettings.newdealAttachmentsList);
      envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    }
    else if(formType==AppSettings.FormTypes.ClosingCheckListForm)
    {
        appendedURL = this.getAppendURL(AppSettings.closingCheckListAttachmentsList);
        envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    }
    else
    {
      appendedURL = this.getAppendURL(AppSettings.dealWbslAttachmentsList);
      envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
    }  
    
    const url = envURL + '/' + appendedURL + '/Add';
     return this.myhttp.post(url,attachmentReqObj,{
      headers: new HttpHeaders({        
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    });
  }

  AddAdditionalDocumentTypeAtachment(attachmentReqObj:any):Observable<any>
  {   
    const appendedURL = this.getAppendURL(AppSettings.additionalDocumentTypesList);
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    const url = envURL + '/' + appendedURL + '/Add';
     return this.myhttp.post(url,attachmentReqObj,{
      headers: new HttpHeaders({        
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    });
  }

   uploadFileWithPresignedURL(preSignedUrlDetails:any,file:any):Observable<any> {   
     const url = preSignedUrlDetails.Url
     return this.myhttp.put(url, file.fileData,
      {
      headers: new HttpHeaders({        
        'Content-Type' : 'binary/octet-stream',
        "x-amz-server-side-encryption": "aws:kms",
        "x-amz-server-side-encryption-aws-kms-key-id":preSignedUrlDetails.KMSArn
      })
    });
}
    GetDealADGroupDetails(): Observable<any> {
        const url = AppSettings.GetDealADGroupDetails();
        return this.myhttp.get(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("accessToken")
            })
        })
    }
  
  GetLCSPADGroupDetails(term:any): Observable<any> {
    const url = AppSettings.GetLCSPADGroupDetails()+term.toString().trim();
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("accessToken")
      })
    })
    //Below change for LCSP Post Call
    // const url = AppSettings.GetLCSPADGroupDetails();
    // let formData:FormData = new FormData();
    // formData.append("criteria",term.toString().trim()); 
    // return this.myhttp.post(url,formData);
  }
  GetDealTeamUserByEmail(formId:any,email:any):Promise<any>{
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
  const url = envURL +'/api/MyDealDealTeam/GetList?formId='+formId+'&Email='+email;
  return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
  'Authorization' : "Bearer "+localStorage.getItem("idToken")})}).toPromise();
  }
  GetSelectedADGroupDetails(search:any): Observable<any> {
    const url=AppSettings.GetSelectedADGroupDetails(search);
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("accessToken")
      })
    })
  }
  getTooltipsList() : Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    const url = envURL +'/api/NewDealTooltips/GetList';
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
}
getDealTeamTableListForAutoSave(formID?:any,email?:any) : Promise<any> {
  let queryParam;
  
  if(formID==null || formID == undefined){
    queryParam="Email="+email;
  }
  else if(email==null || email == undefined){
    queryParam="formId="+formID;
  }
  else{
    queryParam= 'formId='+formID+'&Email='+email;
  }
  
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
  const url = envURL +'/api/MyDealDealTeam/GetList?'+queryParam;
  return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
  'Authorization' : "Bearer "+localStorage.getItem("idToken")})}).toPromise();
}
 GetNewDealDetailsForAutoSave(formID?:any):Promise<any>{
      let url;
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
      if (formID != null || formID != undefined) {
        // url = environment.apiUrl +'/api/NewDealSetupForm/GetList?formID='+formID; 
         url = envURL+'/api/NewDealSetupForm/GetList?formID='+formID; 
      }
      else{
        // url = environment.apiUrl + '/api/NewDealSetupForm/GetList'; 
         url = envURL+'/api/NewDealSetupForm/GetList';
      }
  
      return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})}).toPromise();
    }
    GetMyDealDetailsForAutoSave(formID?:any):Promise<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    
      let url;
      if(formID!=null || formID!=undefined){
        //  url = environment.apiUrl +'/api/MyDeal/GetList?formID='+formID; 
        url = envURL+'/api/MyDeal/GetList?formID='+formID; 
      }
      else{
        //  url = environment.apiUrl + '/api/MyDeal/GetList'; 
         url = envURL+'/api/MyDeal/GetList';
      }
      return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})}).toPromise();
      }
getDealTeamTableList(formID?:any,email?:any) : Observable<any> {
  let queryParam;
  
  if(formID==null || formID == undefined){
    queryParam="Email="+email;
  }
  else if(email==null || email == undefined){
    queryParam="formId="+formID;
  }
  else{
    queryParam= 'formId='+formID+'&Email='+email;
  }
  
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
  const url = envURL +'/api/MyDealDealTeam/GetList?'+queryParam;
  return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
  'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
}
getAsteriskList() : Observable<any> {
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
  const url = envURL +'/api/NewDealValidationFields/GetList';
  return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
  'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
}
  
GetUserRoleGroups(userId:any):Promise<any>{
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
  const url = envURL +'/api/UserGroups/GetUserGroupsByEmail?email='+userId;
  return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
  'Authorization' : "Bearer "+localStorage.getItem("idToken")})}).toPromise();
}
GetAppConfigurationDetails(formType:any):Observable<any>{
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorderDashboard)
  const url = envURL +'/api/AppConfigurations/CM_GetList?formType='+formType;
  return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
  'Authorization' : "Bearer "+localStorage.getItem("idToken")})});
}
SaveFormDetails(appendedURL:any,data:any):Promise<any>{
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
  const url = envURL+'/'+appendedURL+'/Add'; 
  return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
  ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })}).toPromise();
}

SaveFormDetailsList(appendedURL:any,data:any):Promise<any>{
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
  const url = envURL+'/'+appendedURL+'/AddList'; 
  return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
  ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })}).toPromise();
}
SaveEngagementDetails(appendedURL:any,data:any):Promise<any>{
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.EngagementDashboard)
  const url = envURL + '/'+appendedURL+'/Add'; 
  return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
  ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })}).toPromise();
}
UpdateNewDealFormDetails(appendedURL:any,itemID:any,data:any):Promise<any>{
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
  const url = envURL+'/'+appendedURL+'/Update'; 
  return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
  ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })}).toPromise();
}
GetFormStatusBck(formID:any):Promise<any>{
  var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
  const url = envURL+'/api/NewDealSetupForm/GetList?formID='+formID; 
  return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
  'Authorization' : "Bearer "+localStorage.getItem("idToken")})}).toPromise();
}
  GetNewDealDetails(formID?:any):Observable<any>{
    let url;
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    if (formID != null || formID != undefined) {
       url = envURL+'/api/NewDealSetupForm/GetList?formID='+formID; 
    }
    else{
      url = envURL+'/api/NewDealSetupForm/GetList';
    }

    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
  }
  GetMyDealDetails(formID?:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    let url;
    if(formID!=null || formID!=undefined){
      url = envURL+'/api/MyDeal/GetList?formID='+formID; 
    }
    else{
      //  url = environment.apiUrl + '/api/MyDeal/GetList'; 
      url = envURL+'/api/MyDeal/GetList';
    }
    return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization' : "Bearer "+localStorage.getItem("idToken")})})
    }
    GetGroupData(groupName:any): Promise<any> {
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)        
       const url = envURL+'/api/UserGroups/GetUserGroupsByRole?role='+groupName; 
        return this.myhttp.get(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("idToken")
            })
        }).toPromise();
    }
    GetRoleAccessDetails(filterUrl: string): Observable<any> {
      var url="";var envURL="";
      if(this.globalHelperService.currentAccessPage?.toString()=="DealWBSForm"){
        envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
        url = envURL+'/api/DealWbsSecurity/GetList?'+filterUrl; 
     }
     else if(this.globalHelperService.currentAccessPage?.toString()=="ClosingChecklistForm"){
       envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
       url = envURL+'/api/ClosingCheckListSecurity/GetList?'+filterUrl; 
    }
     else{
        envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
        url = envURL+'/api/NewDealSecurity/GetList?'+filterUrl; 
     }
    
    //   if(localStorage.getItem("CurrentAccessPage")?.toString()=="DealWBSForm"){
    //      envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DealWbsform)
    //      url = envURL+'/api/DealWbsSecurity/GetList?'+filterUrl; 
    //   }
    //   else if(localStorage.getItem("CurrentAccessPage")?.toString()=="ClosingChecklistForm"){
    //     envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.ClosingChecklistForm)
    //     url = envURL+'/api/ClosingCheckListSecurity/GetList?'+filterUrl; 
    //  }
    //   else{
    //      envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
    //      url = envURL+'/api/NewDealSecurity/GetList?'+filterUrl; 
    //   }
     
        return this.myhttp.get(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("idToken")
            })
        })
    }

    UpdateForm(formName:any,data:any):Promise<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
      const appendedURL = this.getAppendURL(formName);      
      const url = envURL + '/' + appendedURL + '/Update';												
      return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})
    }).toPromise();
    }

    GetGroupUsers(groupName:any):Promise<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
      const appendedURL = this.getAppendURL(groupName); 
      const url = envURL + '/' + appendedURL + '/GetList';   
      
      return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})
    }).toPromise();
    }
    updateGroupData(formName:any,adminName:any,object:any):Promise<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
      const appendedURL = this.getAppendURL(formName);   
      const url = envURL + '/' + appendedURL + '/Update';
      const data =JSON.stringify({adminName:adminName,updateValues:object})     
															
      return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})
    }).toPromise();
    }

    AddUserstoGroup(formName:any,requestObject:any):Promise<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
							   
      const data =JSON.stringify(requestObject) 
      const appendedURL = this.getAppendURL(formName); 
      const url = envURL + '/' + appendedURL + '/Add';
           
      return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})
    }).toPromise();
    }
    DeleteUsersFromGroup(formName:any,userToDelete:any):Promise<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
      const appendedURL = this.getAppendURL(formName); 
      const url = envURL + '/' + appendedURL + '/Delete';
      const data =JSON.stringify(userToDelete) 
          
      return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})
    }).toPromise();
    }
    GetS24Users(): Observable<any> {
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
         const url = envURL+'/api/UserGroups/GetUserGroupsByRole?role=MyDeal_S24'; 
        return this.myhttp.get(url, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("idToken")
            })
        })
    }
    getCommentsData(formName:any,formId:any):Observable<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
      const appendedURL = this.getAppendURL(formName);      
      const url = envURL + '/' + appendedURL + '/GetList?formID='+formId;          
      return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})
    });
    }
    DeleteListItem(appendedURL:any,data:any):Promise<any>{
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)
      const url = envURL+ '/' + appendedURL + '/Delete';          
      return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})
    }).toPromise();
    }
    getDealTeamAuditLogData(formName:any,formId:any):Promise<any>{
      const appendedURL = this.getAppendURL(formName);      
      var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.Newdealform)    
      const url = envURL + '/' + appendedURL + '/GetList?formId='+formId;          
      return this.myhttp.get(url,{headers : new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization' : "Bearer "+localStorage.getItem("idToken")})
    }).toPromise();
    }

  }
