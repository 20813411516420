 <div
  id="admin-review"
  [formGroup]="adminReviewFormGroup"
  class="container-fluid border container-border-color container-section">
  <div class="row bg-title"> 
      <span class="title-section-print">Admin Review</span>
  </div>
  <div id="admin-review-section" class="content-section">
      <div class="row">
          <div class="col-xs-4 section-label">
              <span [class.highlight-warning]="highlightRequiredField('salesforceCode')"
              [class.edited-externally]="editedExternally('salesforceCode')"
              [class.conflict-on-merge]="hasMergeConflict('salesforceCode')">Salesforce Code</span>
              <span *ngIf="showAsterisk('salesforceCode')" class="red-asterik"></span>
          </div>
          <div class="col-xs-8">
              <input
              [class.readonly]="isReadOnly('salesforceCode')"
              formControlName="salesforceCode"
              class="input-data-text full-width section-input"
              type="text"
              autocomplete="off">
          </div>
      </div>
      <div class="row">
          <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('opportunityID')"
            [class.edited-externally]="editedExternally('opportunityID')"
            [class.conflict-on-merge]="hasMergeConflict('opportunityID')">Mercury Opportunity Code</span>
            <span *ngIf="showAsterisk('opportunityID')" class="red-asterik"></span>
          </div>
           <div class="col-xs-8">
              <input
              [class.readonly]="isReadOnly('opportunityID')"
              [formControl]="myDealFormGroup.get('opportunityID')"
              class="input-data-text full-width section-input"
              type="text"
              autocomplete="off">
          </div> 
      </div>
      <div class="row">
        <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('swiftCode')"
            [class.edited-externally]="editedExternally('swiftCode')"
            [class.conflict-on-merge]="hasMergeConflict('swiftCode')">Swift Code</span>
            <span *ngIf="showAsterisk('swiftCode')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
            <input
            [class.readonly]="isReadOnly('swiftCode')"
            formControlName="swiftCode"
            class="input-data-text full-width section-input"
            type="text"
            autocomplete="off">
        </div>
      </div>
      <div *ngIf="!adminApprovalName && editable" class="row" style="float: right;">
        <div class="col-xs-12 submit-buttons">
          <button
            class="btn btn-primary btn-approve approval-button"
            [disabled]="saveInProgress"
            (click)="adminApproval()">
            Complete
          </button>
          <button *ngIf="saveButtonVisible"
            class="btn btn-outline-primary save-rework-cancel-btn margin-left-10"
            [disabled]="saveInProgress"
            (click)="adminSave()">
            Save
          </button>
          <button
            class="btn btn-outline-primary save-rework-cancel-btn margin-left-10"
            [disabled]="saveInProgress"
            (click)="adminReturnForRework()">
            Return for rework
          </button>
          <button
            (click)="adminDiscontinue()"
            class="btn btn-outline-primary save-rework-cancel-btn margin-left-10"
            [disabled]="saveInProgress">
            Discontinue
          </button>
        </div>
      </div>
      <div *ngIf="adminApprovalName" class="row">
        <div class="border-admin-review-section">
          <div class="approval-signature-admin-review-section">
            <i>Admin</i>
            <div>
              <span class="signature-color name-admin-review-section"><b>{{adminApprovalName}}</b></span>
            </div>
            <hr class="hr-admin-review-section">
          </div>
          <div class="approval-date-admin-review-section">
            <span class="fa fa-check-circle reviewed-icon-color reviewed-icon-admin-review-section"></span>
            <span class="date-admin-review-section"><b>{{adminApprovalDate}}</b></span>
          </div>
        </div>
      </div>
  </div>
</div>
